
.main-container {
    padding: 20px;
    background-color: white;
}
.input-box-performance {
  margin-bottom: 8px;
  width: calc(100% / 5 - 20px);
}
.input-box {
    margin-bottom: 8px;
    width: calc(100% / 3 - 20px);
  }
  .input-box span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
 .input-box input {
    cursor: pointer;
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 17px;
    font-weight: 500;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
  }
  .input-box input:focus{
    border: 2px solid black;
  }
  .aadhar {
    padding-top: 10px;
    padding-left: 5px;
  }
  .aadhar:hover {
    padding-top: 10px;
    padding-left: 5px;
    text-decoration: underline;
    cursor: pointer;
  }

  

  .product-item {
    position: relative;
    display: inline-block;
    min-width: 50px;
    max-width: 520px;
    width: 100%;
    background-color: transparent;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    border: 1px solid rgba(157, 157, 157, 0.345);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1);
    transition: 0.5s ease-in-out;
    
  }
  .product-item:hover{
    transform: scale(1.1);
  }
  

  .product-item .product-image {
    padding-bottom:5px;
  }
  
  .product-item .product-image img {
    vertical-align: top;
    border-radius: 5px;
    width: 100%;
    height: 260px;
  }

  .circle {
    cursor: pointer;
    position: fixed;
    bottom: 8%;
    right: 5%;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
    background: #fff;
    border: 3px solid rgb(0, 55, 255);
    color: rgb(255, 255, 255);
    background-color: rgb(0, 55, 255);
    text-align: center;
    font: 20px Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}


.toggle-container {
  text-align: left;
  }
  .toggle-switch {
  position: relative;
  width: 45px;
  display: inline-block;
  text-align: left;
  top: 5px;
  }
  .checkbox {
  display: none;
  }
  .label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
  }
  .inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  }
  .inner:before,
  .inner:after {
  float: left;
  width: 50%;
  height: 24px;
  padding: 0;
  line-height: 24px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
  }
  .inner:before {
  content: "";
  padding-left: 10px;
  background-color: #060;
  color: #fff;
  }
  .inner:after {
  content: "";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
  }
  .switch {
  display: block;
  width: 15px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
  }
  .checkbox:checked + .label .inner {
  margin-left: 0;
  }
  .checkbox:checked + .label .switch {
  right: 0px;
  }
  
  .table-main-header {
    display: flex;
    justify-content: space-between;
padding: 10px;
border: 2px solid black;
margin: 10px;
}

.bill-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
   
}
.total-header {
  display: flex;
  justify-content: space-between;
}

.button-31 {
  background-color: darkblue;
  border-radius: 4px;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  /* display: inline-block; */
  font-family: "Farfetch Basis","Helvetica Neue",Arial,sans-serif;
  /* font-size: 16px; */
  font-weight: 700;
  /* line-height: 1.5; */
  margin: 0;
  max-width: none;
  /* min-height: 44px;
  min-width: 10px; */
  outline: 1px solid black;
  overflow: hidden;
  padding: 9px 20px 8px;
  position: relative;
  text-align: center;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  /* width: 100%; */
}

.button-31:hover,
.button-31:focus {
  opacity: .75;
}