.dialoguePage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-Color: rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.dialogueBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 890px;
    height: 630px;
}
form .user-details {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 10px 0 1px 0;
  }

form .user-details .input-box {
    margin-bottom: 8px;
    width: calc(100% / 2 - 20px);
  }
  
  form .input-box span.details {
    display: block;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  .user-details .input-box input {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
  }
 
  
  .user-details .input-box input:focus,
  .user-details .input-box input:valid {
    border-color: #9b59b6;
  }
  
  form .gender-details .gender-title {
    font-size: 20px;
    font-weight: 500;
  }
  
  form .category {
    display: flex;
    width: 80%;
    margin: 14px 0;
    justify-content: space-between;
  }
  
  form .category label {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  form .category label .dot {
    height: 18px;
    width: 18px;
    border-radius: 50%;
    margin-right: 10px;
    background: #d9d9d9;
    border: 5px solid transparent;
    transition: all 0.3s ease;
  }
  
  #dot-1:checked~.category label .one,
  #dot-2:checked~.category label .two,
  #dot-3:checked~.category label .three {
    background: #9b59b6;
    border-color: #d9d9d9;
  }
  
  form input[type="radio"] {
    display: none;
  }
  
  form .button {
    height: 45px;
    margin: 35px 0
  }
  
  form .button input {
    height: 100%;
    width: 100%;
    border-radius: 5px;
    border: none;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 1px;
    cursor: pointer;
    transition: all 0.3s ease;
    background: linear-gradient(to right, #003cff, #003cff);
  }
  
  form .button input:hover {
    /* transform: scale(0.99); */
    /* background: linear-gradient(-135deg, #71b7e6, #9b59b6); */
    background: linear-gradient(to right,  #003cffea, #003cff);
    ;
  }
  
.head{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
 
.imageSelect {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-left: 15px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease;
  }

  .dropdown {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 18px;
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 10px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    resize: none;
    letter-spacing: -1px;
    cursor: pointer;
}
.dropdown-list {
    list-style-type: none;
    position: absolute;
    margin: 0;
    padding: 0;
    width:408px;
    height: 180px;
    background: rgb(255, 255, 255);
    box-shadow: 0 2px 4px 0 rgba(black, .5);
    z-index: 1;
    overflow: auto;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.458);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);


}

.dropdown-list .option {
    padding: 8px 0 5px 13px;
    cursor: pointer;
    font-weight: 550;
}

.dropdown-list .option:hover {
    color: white;
    background: #4db8ff;
}

.arrow {
  position: relative;
}

.arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 0 7px;
  border-color: rgba(0, 0, 0, 0.584) transparent transparent transparent;
  position: absolute;
  right: 10px;
  top: -8px;
}

.radio-option{
  border: none;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	break-before: always;
	margin: 0.3em 0 0.3em 0;
}
.radio-item{
  margin: 0.4em 1.81em 0 0;
}

input[type='radio']{
  width: 20px;
  height: 20px;
}
textarea[disabled]
{
  cursor: not-allowed;
  opacity: 0.7;
}
input[disabled]
{
  cursor: not-allowed;
  opacity: 0.4;
}