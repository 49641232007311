.image-upload.center {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }
  
  .image-upload__preview {
    width: 3rem;
    height: 2.7rem;
    /* border: 1px solid #ccc; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* margin-bottom: 1rem; */
  }
  
  .image-upload__preview img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .select-button {
    height: 45px;
    width: 100%;
    outline: none;
    font-size: 16px;
    border-radius: 5px;
    padding-top: 8px;
    font-weight: 500;
    color: rgb(110, 110, 110);
    /* padding-left: 15px; */
    /* border: 1px solid #ccc;
    border-bottom-width: 2px;
    transition: all 0.3s ease; */
  }