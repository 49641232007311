.confirm-dialoguePage {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-Color: rgba(0, 0, 0, 0.5);
    z-index: 100;
}

.confirm-dialogueBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 330px;
    height: fit-content;
}

.confirm-dialogueButtons {
    display: flex;
    align-items: "center";
    justify-content: space-evenly;
}

.confirm-DialogueCancel {
    background: rgb(152, 152, 152);
    color: black;
    padding: 10px 20px;
    margin-left: 4px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
}

.confirm-DialogueLogout {
    background: rgb(255, 60, 0);
    color: white;
    padding: 10px 20px;
    margin-right: 4px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 500;
}

.confirm-DialogueTitle {
    font-weight: 600;
    text-align: center;
}

.confirm-DialoguePara {
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}