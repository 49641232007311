nav {
    display: flex;
    justify-content: end;
    align-items: center;
    height: 20px;
    padding: 0 30px;
    gap: 20px;
    background-color: white
}


.logout {
   
    padding: 13px 10px;
    list-style: none;
    margin: 5px;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    color: white;
    background:#f4433699
}

.logout:hover {
    background-color: #f44336;
    color: white;
}
.logout a {
    color: currentColor;
    display: flex;
    align-items: center;
}

.logout a i {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
