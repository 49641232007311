.img-loader {      
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -28px 0 0 -25px;
}
.wrapper {
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    backdrop-filter: blur(0px);
}