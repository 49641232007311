.App{
  background-image: url(../images/route.jpg);
  background-size: 1520px;
}
.container{
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.8rem;
  background: #e9eff2e1;
  
}

.page-title{
  font-weight: 600;
  font-size: 2.5rem;
  color: #3461FD;

}

.page-desc{
  padding-bottom: 10px;
  font-weight: 600;
  font-size: 1rem;
  color: #404040;
}

.form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 20rem;
  width: 100%;
  position: relative;
  gap: .5rem;
}

.input{
  width: 100%;
  display: block;
  font-size: 1rem;
  padding: .75rem 1rem;
  border: 1px solid #F9FBFC;
  background: #F9FBFC;
  border-radius: .5rem;
  transition: all .25s ease 0s;
  color: #212325;
  box-shadow: 1px 2px 4px 0px #0b163c40;
}
.password-container{
  width: 100%;
  display: flex;
  font-size: 1rem;
  padding-right: 5px;
  /* border: 1px solid #F9FBFC; */
  background: #F9FBFC;
  border-radius: .5rem;
  transition: all .25s ease 0s;
  color: #212325;
  box-shadow: 1px 2px 4px 0px #0b163c40;
  align-items:center
}
.password-container:hover{
  box-shadow: 1px 2px 4px 0px #0b163c40, 0 0 0 3px #3461FD80;
}

.input:hover{
  border-color: #3461FD;
  box-shadow: 1px 2px 4px 0px #0b163c40, 0 0 0 3px #3461FD80;
}

.btn{
  width: 100%;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  padding: .75rem 1rem;
  border: 1px solid #3461FD;
  background: #3461FD;
  border-radius: .5rem;
  transition: all .25s ease 0s;
  color: #E9EFF2;
  cursor: pointer;
  box-shadow: 0px 3px 5px 0px #3461FD40;
}

.btn:hover, .btn:focus{
  background: #3461FDDD;
  border-color: #3461FDDD;
  box-shadow: 0px 3px 5px 0px #3461FD40, 0 0 0 3px #3461FD80;
}

.forgot-link{
  font-weight: 600;
  font-size: .85rem;
  width: 100%;
  text-align: end;
  color: #3461FD;
  text-decoration: none;
  cursor: pointer;
}

.forgot-link:hover, .forgot-link:focus{
  text-decoration: underline;
}

.password-container-user{
  width: 100%;
  display: flex;
  font-size: 1rem;
  padding-right: 5px;
  border: 1px solid #848484;
  border-radius: .5rem;
  transition: all .25s ease 0s;
  color: #212325;
  /* box-shadow: 1px 2px 4px 0px #0b163c40; */
  align-items:center
}
