body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
.change::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: red;
}
.change:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color:red;
  opacity: 1;
}
.change::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: red;
  opacity: 1;
}
.change:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: red;
}


input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        display: none;
      }

::-webkit-scrollbar {
  width: 4px;
}

/* ::-webkit-scrollbar-track {
  background: #f1f1f1;
} */
::-webkit-scrollbar-thumb {
  background: #999999;
}

.dot {
  height: 8px;
  width: 9px;
  border-radius: 50%;
  display: inline-block;
}