/* ------- Sidebar style ------- */
.sidebar {
    position: fixed;
    width: 250px;
    height: 100vh;
    /* background-color: var(--darkblue); */
    background-color: rgb(193, 209, 233);
   
    font-weight: 600;
    color: rgb(0, 0, 0);
    
    
}

.sidebar .logo {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    padding: 15px 20px 20px 20px;
    background-color: rgb(193, 209, 233);
    
}

.sidebar ul {
    /* margin-top: 50px; */
    list-style: none;
}

.sidebar ul li {
    cursor: pointer;
    padding: 12px 10px;
    margin: 5px;
    border-radius: 5px;
}

.sidebar ul li a {
    color: currentColor;
    display: flex;
    align-items: center;
}

.sidebar ul li a i {
    width: 40px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar ul li.active {
    background-color: var(--lightcolor);
    color: var(--darkblue);
}

.sidebar ul li:hover {
    background-color: rgba(247, 248, 251, 0.737);
    color: var(--darkblue);
}