/* ------- Global style ------- */
:root {
    --darkblue: #0F1730;
    --lightcolor: rgb(247, 248, 251);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

a {
    color: var(--lightcolor);
    text-decoration: none;
}

.main-container {
    padding: 20px;
    background-color: white;
}



/* ------- Content style ------- */
.content {
    position: fixed;
    right: 0;
    width: calc(100% - 250px);
    height: 100%;
    overflow: scroll;
    background-color: rgb(255, 255, 255);
    

}



header {
    color: var(--darkblue);
}



.tables {
    width: 100%;
    align-items: self-start;
}

.table-content {
    min-height: 550px;
    background: #fff;
    padding: 20px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

table {
    margin-top: 8px;
    width: 100%;
    border-collapse: collapse;
}

thead td {
    font-weight: 750;
    color: #333;
    border-bottom: 2px solid rgb(212, 212, 212);
}

table tr {
    border-bottom: 1px solid rgba(0, 0, 0, 0.053);
}

tbody tr:hover {
    background: rgba(0, 0, 0, 0.04);
    color: black;
    cursor: pointer;

}

.img-view:hover {
    cursor: pointer;
}

table tbody tr:last-child {
    border-bottom: none;
}


td {
    padding:9px 5px;
}

td i {
    padding: 7px;
    color: #fff;
    border-radius: 50px;
}

.table-content table tbody td:last-child {
    white-space: nowrap;
}

.new-button {
    color: white;
    margin-bottom: 10px;
    margin-right: 20px;
    padding: 8px 15px;
    background-color: #060082;
    border: #060082;
    border-radius: 3px;
    cursor: pointer;
}

.switchbutton {
    padding: 15px 0 0 10px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
}

.nextbtn {

    background-color: #4c4c4cd4;
    color: rgb(255, 255, 255);
    padding: 6px 15px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    font-weight: 600;
    margin-left: 10px;
}

.nextbtn:hover {
    background-color: #272727dc;
}

.nextbtn:disabled {
    background-color: #4c4c4c71;
    color: rgba(255, 255, 255, 0.488);
}

.profile-img {
    margin-top: 5px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}

.user-container {
    display: flex;
    flex-wrap: wrap;
}

.user-box {
    border-radius: 5px;
    position: relative;
    width: calc(100%/3 - 40px);
    margin: 20px;
    

}




.snip1559 {
    position: relative;
    display: inline-block;
    min-width: 50px;
    max-width: 520px;
    width: 100%;
    background-color: #a2a2a22b;
    color: #000000;
    text-align: center;
    font-size: 16px;
    border: 1px solid rgba(157, 157, 157, 0.345);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    transform: scale(1);
    transition: 0.5s ease-in-out;
    
  }
  .snip1559:hover{
    transform: scale(1.1);
  }
  

  .snip1559 .profile-image {
    padding: 2% 2%;
  }
  
  .snip1559 .profile-image img {
    vertical-align: top;
    border-radius: 5px;
    width: 80px;
    height: 80px;
  }
  
  .snip1559 figcaption {
    padding-left: 5px;
    text-align: left;
    overflow-wrap: break-word;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
  }
  
  .snip1559 h3 {
    
    font-size: 25px;
    font-weight:bold;
    padding-bottom: 5px;
  }
  .snip1559 p{
  
    font-size: 18px;
  }

  .card-in{
    display: flex;
  }
  
  .search {
    position: relative;
    max-width: 700px;
    padding-bottom: 8px;
  }
  .filter{
    height: 33px;
    padding: 0 8px;
    background: #f5f5f576;
    color: rgb(121, 121, 121);
    border: 1px solid rgb(170, 170, 170);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 8px;
    cursor: pointer;
  }
  .search input {
    width: 350px;
    min-width: 128px;
    height: 35px;
    padding: 0 8px;
    font-size: 16px;
    outline: none;
    border: none;
    background: #f5f5f576;
    border-radius: 4px;
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  }
  
  .search i {
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: rgb(174, 174, 174);
  }
  
  .tabs{
    display: flex;
    margin-left: 20px;
    margin-bottom: 5px;
  }
  .tab{
    
    padding:2px;
    font-weight: 600;
   color: rgba(112, 112, 112, 0.615);
    margin-right: 15px;
    border-color: rgb(0, 17, 255);
    cursor: pointer;
  }
  .activetab{
    padding:2px;
    font-weight: 600;
    border-bottom: 3px solid;
    margin-right: 15px;
    color: rgb(0, 0, 159);
    border-color: rgb(0, 17, 255);
    cursor: pointer;
  }

  .filter-tab{ 
    padding:5px;
    font-weight: 600;
    background-color: #fff;
   color: rgba(163, 163, 163, 0.963);
   border-bottom: none;
   margin-right: 0.2px;
   border-color: rgb(218, 218, 218);
    cursor: pointer;
  }
  .active-filter-tab{
    padding:5px;
    font-weight: 600;
    border-bottom: 3px solid;
    color: rgb(0, 0, 159);
    border-bottom: none;
    border-color: rgb(212, 212, 212);
    cursor: pointer;
  }

  .filter-dropdown {
    outline: none;
    height: 30px;
    padding-top: 5px;
    padding-left: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    border-bottom-width: 2px;
    resize: none;
    letter-spacing: -1px;
    cursor: pointer;
}
.filter-arrow{
  position: relative;
}

.filter-arrow::after {
  content: '';
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 5px 4px 0 5px;
  border-color: rgba(0, 0, 0, 0.584) transparent transparent transparent;
  position: absolute;
  right: 10px;
  top: 0px;
}
.filter-dropdown-list {
  list-style-type: none;
  position: absolute;
  height: 100px;
  width: 174px;
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255);
  box-shadow: 0 2px 4px 0 rgba(black, .5);
  z-index: 1;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.458);
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);


}
.filter-dropdown-list .option {
  padding-left: 5px;
  padding-top: 2px;
  cursor: pointer;
}

.filter-dropdown-list .option:hover {
  color: white;
  background: #4db8ff;
}
.filter-search{
  padding: 2px;
  width: 100%;
}

.if-unverified-shop{
  position: relative;
}
.unverified-msg{
  position: absolute;
  padding: 15px;
  width: 255px;
  color: red;
  top: 37px;
  left: -88px;
  z-index: 9;
  white-space: pre-wrap;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.vehicle-box {
  position: relative;
  display: inline-block;
  min-width: 50px;
  max-width: 520px;
  width: 100%;
  background-color: #bbbbbb7e;
  color: #000000;
  text-align: center;
  font-size: 16px;
  border: 1px solid rgba(157, 157, 157, 0.345);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transform: scale(1);
  transition: 0.5s ease-in-out;
  
}
button:disabled:hover{
  cursor: not-allowed;
  opacity: 50%;
}
button:disabled{
  cursor: not-allowed;
  opacity: 50%;
}
button{
  padding: 0 5px;
  cursor: pointer;
}

.vehicle-tile{
  width:calc(100%/4 - 40px);
   margin: 20px;
   position: relative 
}

@media screen and (max-width: 1300px) {
  .user-box {
    border-radius: 5px;
    position: relative;
    width: calc(100%/2 - 40px);
    margin: 20px;
    

}
.vehicle-tile{
  width:calc(100%/2 - 40px);
   margin: 20px;
   position: relative 
}
}
@media screen and (max-width: 600px) {
  .user-box {
    border-radius: 5px;
    position: relative;
    width: calc(100%/1 - 40px);
    margin: 20px;
    

}
.content {
  position: fixed;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: rgb(255, 255, 255);
  

}
}